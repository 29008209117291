.streams-wrapper {
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: rgba(50, 50, 50, 0.5);
    padding: 20px;
}

.streams {
    .stream-item {
        display: inline-block;
        width: 150px;
        height: 75px;
        margin-right: 20px;
        border: 1px solid white;
        color: white;
        padding: 10px;
        position: relative;
        cursor: pointer;
        transition-duration: 0.5s;
        &:hover {
            opacity: 0.5;
        }

        &.current {
            border-width: 5px;
            padding: 6px;
        }

        .title {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            transform: translate(0, -50%);
            text-align: center;
            font-weight: bold;
        }
    }
}
