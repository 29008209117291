.lineup-wrapper {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100vw;
    background-color: rgba(50, 50, 50, 0.5);
    padding: 20px;
}

.lineup {
    .lineup-item {
        margin-right: 20px;
        display: inline-block;
        padding: 20px;
        background-color: rgba(50, 50, 50, 0.5);
        color: white;
        border: 1px solid #ccc;
        position: relative;
        .title {
            font-weight: bold;
        }
        .live {
            position: absolute;
            top: 10px;
            right: 10px;
            border: 1px solid red;
            color: red;
            font-weight: bold;
            font-size: 11px;
            padding: 3px;
            border-radius: 3px;
            display: inline-block;
        }
    }
}
